<template>
  <v-theme-provider dark>
    <base-section
      id="steps"
      class="accent pa-8"
      space="56"
    >
      <v-row
        justify="space-around"
      >
        <base-icon class="mb-8">
          mdi-trophy-outline
        </base-icon>
      </v-row>

      <base-section-heading
        color="transparent"
        title="Mude sua vida agora!"
      >
        <h3>Deixa eu te mostrar exatamente o que mudaremos na sua vida com as 4 etapas do meu acompanhamento baseado em nutrição moderna:</h3>
      </base-section-heading>

      <v-timeline class="hidden-sm-and-down">
        <v-timeline-item
          v-for="(step, i) in steps"
          :key="i"
          large
          :color="step.color"
          :icon="step.icon"
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${step.color}--text`"
            >
              Etapa {{ i+1 }}
            </span>
          </template>
          <div class="py-4">
            <base-info-card
              :icon="step.icon"
              :color="step.color"
              :title="step.title"
              :html="step.text"
            />
          </div>
        </v-timeline-item>
      </v-timeline>
      <v-row
        v-for="(step, i) in steps"
        :key="i"
        class="hidden-md-and-up"
      >
        <base-info-card
          :icon="step.icon"
          :color="step.color"
          :title="`Etapa ${i+1}: ${step.title}`"
          :html="step.text"
        />
      </v-row>
      <base-btn-plans dark-img />
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionSteps',

    provide: {
      heading: { align: 'center' },
    },

    data () {
      return {
        steps: [
          {
            icon: 'mdi-focus-field',
            title: 'Avaliação',
            color: 'primary',
            text: 'Para saber a melhor forma de te ajudar, primeiro a gente avaliará mais de 40 pontos sobre sua saúde, incluindo preferências alimentares, antecedentes nutricionais, histórico familiar de saúde, práticas de exercício físico, condições incapacitantes, restrições dietéticas, questões psicológicas ou compulsivas, aparecimento de doenças, entre outros tópicos, até pra saber se precisamos fazer exames ou não, pra garantir que esteja tudo certinho.',
          },
          {
            icon: 'mdi-consolidate',
            title: 'Planejamento',
            color: 'amber',
            text: 'Com base em sua rotina, preferências e condições financeiras e de saúde definimos o objetivo e então avaliamos a melhor forma de elaborar seu plano alimentar, ou seja, nós planejaremos <b>juntos</b> como será sua alimentação, sua opinião é essencial pra chegarmos em uma forma de alimentação que procure ser 100% do seu agrado ao mesmo tempo que conquistamos resultados sólidos e duradouros.',
          },
          {
            icon: 'mdi-motion-play',
            title: 'Execução do plano',
            color: 'success',
            text: 'Agora é hora de colocarmos a mão na massa, você receberá seu plano alimentar e junto dele ferramentas e técnicas para tornar sua alimentação o mais fácil e agradável possível. A partir daqui você terá suporte sempre que precisar pra tirar sua dúvida e também fazer ajustes se algo não estiver do seu agrado.',
          },
          {
            icon: 'mdi-clock-check-outline',
            title: 'Reavaliação dinâmica',
            color: 'cyan',
            text: 'Nessa etapa você vai perceber a importância de avaliar constantemente a sua estratégia de evolução, pode acontecer te sua rotina mudar, de você não gostar de algum alimento ou de sentir falta de outro, não importa o que aconteça, estarei aqui pra reavaliar no MÍNIMO uma vez por semana seus resultados e sua alimentação e ajustar sempre que for preciso. Esqueça a ideia de seguir uma dieta chata, cara ou desagradável, mudaremos todo dia, se for necessário!',
          },
        ],
      }
    },
  }
</script>
